<template>
	<div v-if="show">
		<div class="item_line">
			<span class="titlename" style="font-size: 18px;color: #333;font-weight: bold;">{{form.name}}</span>
		</div>
		<div class="item_line">
			<span class="titlename">会议议题</span>
		</div>
		<div class="item_line" style="margin-left: 10px;" v-show="!istz&&updataQx==2">
			<el-button type="primary" size="mini" @click="showselect=!showselect">{{showselect?'取消编辑':'编辑'}}</el-button>
			<div v-show="showselect" style="display: flex;align-items: center;margin-left: 15px;">
				<span>选择议提：</span>
				<el-select v-model="value" placeholder="请选择" @change="seleted" style="width: 300px;">
				    <el-option
				      v-for="item in yitilist"
				      :key="item.id"
				      :label="item.name"
				      :value="item.id">
				    </el-option>
				</el-select>
			</div>
		</div>
		<div style="width: 70%;margin-left: 10px;">
			<div style="display: flex;width: 100%;align-items: center;">
				<div style="flex:4;font-weight: bold;font-size: 16px;">
					议题
				</div>
				<div style="flex:1.5;font-weight: bold;font-size: 16px;">
					汇报人
				</div>					
			</div>
			<div style="width: 100%;margin-top: 5px;margin-bottom: 5px;" v-for="(item,index) in form.yt" :key="index">
				<div style="display: flex;width: 100%;align-items: center;">
					<div style="flex:4;">
						{{item.name}}
					</div>
					<div style="flex:1.5;">
						{{item.hbuser_name}}
					</div>
				</div>					
				<div v-show="item.fujian" style="width: 100%;display: flex;align-items: center;">
					<div style="flex: 5;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #999;">
						<a :href="item.fujian" target="_blank">附件：{{item.fujiananem}}</a>						
					</div>						
					<div style="flex: 1.5;display: flex;align-items: center;color: #d00;">
						
					</div>
				</div>
				<div style="display: flex;align-items: center;justify-content: center;width: 100%;margin-top: 5px;">
					<div style="width: 100%;height: 1px;background-color: #eee;">
						
					</div>
				</div>
				
			</div>
		</div>
		
	</div>

</template>

<script>
	export default {
		props:{
			istz:{
				type:Boolean,
				default:false
			},
			updataQx:{
				type:Number,
				default:1
			}
		},
		data() {
			return {
				show:false,
				form:'',
				showselect:false,
				yitilist:[],
				value:'',
				roleid:0
			}
		},
		created(){
			this.roleid = localStorage.getItem('role_id')
		},
		watch:{
			show(newval,oldval){
				if(newval){
					this.getlist()				
				}
			}
		},
		methods: {
			getlist(){
				this.$post({
					url:'/api/topics/index',
					params: {
						ding: 6,
						p: 1,
						size: 999
					}
				}).then((res) => {
					this.yitilist = res.list
				})
			},
			seleted(e){
				this.$post({
					url:'/api/wymeeting/edit2', 
					params:{
						topics_id: e,
						id:this.form.id,
					}
				}).then((res) => {
					this.$message.success('操作成功')
					this.showselect = false
					this.$emit('getinfo')
					this.getinfo()
				})
			},
			getinfo(){
				this.$post({
					url: '/api/wymeeting/details',
					params: {
						id:this.form.id
					}
				}).then((res) => {
					this.form = res	
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.tapmenu{
		font-size: 16px;
		margin-right: 16px;
		cursor: pointer;
		padding-bottom: 5px;
	}
	.active_tap{
		color: #ec4a4a;	
		border-bottom: 2px solid #ec4a4a;
	}
	.item_line{
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		.titlename{
			margin-left: 10px;
			font-size: 14px;
			
			text-align: right;
			color: #333;
		}
		.bticon{
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}
	.infobox{
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;
		.contbox{
			display: flex;
			padding:20px 40px;
			height: 750px;
			transition: all 0.5s; 
			overflow: hidden;
		}
		.colosecont{
			height: 0;
			padding: 0;
		}
		.title{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}
	.addbox {
		height: 85vh;
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;
		
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		padding: 10px 0;
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-form-item__error{
		padding-top: 0;
	}
	::v-deep .el-form-item {
		margin-bottom: 10px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: '' !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}
	::v-deep .myinputicon{
		cursor: pointer;
	}
	::v-deep .myinputicon:hover{
		color: red !important;
	}
</style>